<template>
  <div class="grid">
  <div class="col-6">
    <h3>Опросы</h3>
    <ul class="survey-list">
      <li v-for="survey in surveys" :key="survey.id" class="flex justify-content-between align-items-center">
        <div>
          <div>
            <router-link :to="`/surveys/${survey.id}`">{{ survey.title }}</router-link>
            <span>(CourseID:{{ survey.survey_id }}, ModuleID: {{ survey.survey_module }})</span>
          </div>
          <span class="m-0 subtext">Экранов: {{ survey.number_of_screens }}</span>
          <span class="m-0 ml-2 subtext">Студентов: {{ survey.students_started }} (завершило: {{ survey.students_completed }})</span>
        </div>
        <div>
          <router-link :to="`/?courseId=${survey.survey_id}&moduleId=${survey.survey_module}&studentId=1&isCheck`" target="_blank"><Button severity="link" icon="fa fa-eye" /></router-link>
          <Button @click="openEditDialog(survey)" severity="link" icon="fa fa-edit" />
          <!-- <Button @click="getExcelFile(survey.survey_id, survey.survey_module)" severity="link" icon="fa-solid fa-download" /> -->
          <Button @click="openStudentsDialog(survey)" severity="link" icon="fa fa-users" />
          <Button @click="confirmDeletion(survey.id)" class="delete-action" severity="link" icon="fa fa-trash" />
        </div>
      </li>
    </ul>
    <Button label="Создать" icon="fa fa-plus" @click="showCreateDialog = true" />
    <NewSurvey :visible="showCreateDialog" @update:visible="showCreateDialog = $event" @surveyCreated="fetchSurveys" />

    <Dialog
    v-model:visible="showStudentsDialog"
    header="Список студентов"
    :modal="true"
    :closable="true"
    style="width: 600px;"
  >
    <!-- Фильтр для поиска по уникальному ID -->
    <div class="p-d-flex p-jc-between p-ai-center">
      <InputText v-model="searchQuery" placeholder="Поиск по ID студента" class="p-mb-3" />
    </div>

    <DataTable
      :value="filteredStudents"
      paginator
      rows="100"
      :globalFilterFields="['unique_id']"
      class="p-datatable-gridlines"
    >
      <Column field="unique_id" header="ID Студента" />
      <Column field="completed" header="Завершено" :body="completedTemplate" />
      <Column header="Действия">
        <template #body="slotProps">
          <Button
            icon="fa-solid fa-magnifying-glass"
            @click="loadStudentProgress(slotProps.data.unique_id, slotProps.data.survey_id)"
          />
        </template>
      </Column>
    </DataTable>

    <p v-if="filteredStudents.length === 0" class="p-text-center">Список студентов пуст.</p>
  </Dialog>

    <!-- Confirmation Dialog -->
    <Dialog 
      v-model:visible="showConfirmDialog" 
      header="Подтверждение удаления" 
      :modal="true" 
      :closable="false"
    >
      <p>Вы уверены, что хотите удалить этот опрос?</p>
      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showConfirmDialog = false" class="p-button-secondary" />
        <Button label="Удалить" @click="confirmDeletionConfirmed" class="p-button-danger" />
      </div>
    </Dialog>

    <!-- Edit Survey Dialog -->
    <Dialog 
      v-model:visible="showEditDialog" 
      header="Редактировать опрос" 
      :modal="true" 
      :closable="false"
       class="edit-survey"
    >
      <div class="p-field">
        <label for="edit-title">Название</label>
        <InputText type="text" v-model="editSurvey.title" id="edit-title" />
      </div>
      <div class="p-field">
        <label for="edit-id">ID курса</label>
        <InputText type="text" v-model="editSurvey.survey_id" id="edit-id" />
      </div>
      <div class="p-field">
        <label for="edit-id">ID модуля</label>
        <InputText type="text" v-model="editSurvey.survey_module" id="edit-id" />
      </div>
      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showEditDialog = false" class="p-button-secondary" />
        <Button label="Сохранить" @click="surveyUpdate" class="p-button-success" />
      </div>
    </Dialog>

    <!-- Student Progress -->
    <Dialog v-model:visible="showProgressDialog" :header="`Прогресс студента (ID: ${studentUniqueId})`" :modal="true" :closable="true" style="width:750px;">
      <ul class="progress-list" v-if="studentAnswers.length">
        <li v-for="answer in studentAnswers" :key="answer.question_title">
          <b class="mb-1 block">{{ answer.question_title }}</b>
          <p v-if="answer.child_answers && !answer.child_answers.length" class="mb-0 mt-0">{{ answer.answer_value || 'Ответ не указан' }}</p>
          <!-- Отображаем дочерние вопросы -->
          <ul class="progress-list" v-if="answer.child_answers && answer.child_answers.length" >
            <li v-for="child in answer.child_answers" :key="child.question_title" class="ml-3 flex align-items-center">
              <b>{{ child.variant }}:</b>{{ child.answerValue || 'Ответ не указан' }}
            </li>
          </ul>
          <ul class="progress-list" v-if="answer.dynamic_answers && answer.dynamic_answers.length" >
            <li v-for="child in answer.dynamic_answers" :key="child.question_title" class="ml-3 flex align-items-center">
              <b>{{ child.variant }}:</b>{{ child.answerValue || 'Ответ не указан' }}
            </li>
          </ul>
        </li>
      </ul>
      <p v-else>Ответы отсутствуют.</p>
    </Dialog>
  </div>
  <div class="col-6">
  <FileUpload />
  </div>

</div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { getSurveys, deleteSurvey, updateSurvey /*, getExcelStats */, getStudentProgress,  getStudentSurveyAnswers } from '../../services/apiService';
import NewSurvey from '../../components/admin-editor/NewSurvey.vue';
import FileUpload from '../../components/admin-editor/FileUpload.vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const surveys = ref([]);
// const studentProgress = ref([]);
const studentAnswers = ref([]);
const showProgressDialog = ref(false);
const showCreateDialog = ref(false);
const showConfirmDialog = ref(false);
const showEditDialog = ref(false);
const surveyToDelete = ref(null);
const editSurvey = ref({ id: '', title: '', survey_id: '', survey_module: '' });

const showStudentsDialog = ref(false);
const selectedSurveyStudents = ref([]);
const searchQuery = ref('');
const studentUniqueId = ref('');  // Это будет хранить unique_id студента

const fetchSurveys = async () => {
  try {
    const response = await getSurveys();
    surveys.value = response.data;
  } catch (error) {
    console.error('Error fetching surveys:', error);
  }
};

/* const getExcelFile = async (surveyId, moduleId) => {
  try {
    // Отправляем запрос на сервер, указывая что мы ожидаем файл в формате blob
    const response = await getExcelStats(surveyId, moduleId, { responseType: 'blob' });

// Создаем ссылку для скачивания файла
const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' })); // Изменен тип на 'text/csv'
const link = document.createElement('a');
link.href = url;

// Назначаем имя файла
link.setAttribute('download', `survey_${surveyId}_${moduleId}.csv`); // Изменено расширение на .csv

// Добавляем ссылку в DOM и кликаем по ней для автоматического скачивания
document.body.appendChild(link);
link.click();

// Удаляем временную ссылку после скачивания
document.body.removeChild(link);
} catch (error) {
  console.error('Error downloading the CSV file:', error); // Изменено сообщение на CSV
}

}; */

const loadStudentProgress = async (studentId, surveyId) => {
  try {
    const response = await getStudentSurveyAnswers(studentId, surveyId);
    studentAnswers.value = response.data;
    studentUniqueId.value = studentId;  // Устанавливаем unique_id студента в реактивное свойство

    showProgressDialog.value = true;
  } catch (error) {
    console.error('Error fetching student answers:', error);
  }
};
// Фильтрация студентов по запросу
const filteredStudents = computed(() => {
  return selectedSurveyStudents.value.filter((student) =>
    student.unique_id.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Загрузка студентов для выбранного опроса
const openStudentsDialog = async (survey) => {
  try {
    const response = await getStudentProgress({ survey_id: survey.id });
    selectedSurveyStudents.value = response.data;
    console.log(selectedSurveyStudents.value);
    showStudentsDialog.value = true;
  } catch (error) {
    console.error('Ошибка при загрузке студентов:', error);
  }
};

// Шаблон для отображения статуса завершения
const completedTemplate = (rowData) => {
  return rowData.completed ? 'Да' : 'Нет';
};

const confirmDeletion = (id) => {
  surveyToDelete.value = id;
  showConfirmDialog.value = true;
};

const confirmDeletionConfirmed = async () => {
  try {
    await deleteSurvey(surveyToDelete.value);
    fetchSurveys();
  } catch (error) {
    console.error('Error deleting survey:', error);
  } finally {
    showConfirmDialog.value = false;
    surveyToDelete.value = null;
  }
};

const openEditDialog = (survey) => {
  editSurvey.value = { ...survey };
  showEditDialog.value = true;
};

const surveyUpdate = async () => {
  try {
    await updateSurvey(editSurvey.value.id, editSurvey.value);
    fetchSurveys();
  } catch (error) {
    console.error('Error updating survey:', error);
  } finally {
    showEditDialog.value = false;
    editSurvey.value = { id: '', title: '', survey_id: '', survey_module: '' };
  }
};

onMounted(() => {
  fetchSurveys();
  // fetchStudentProgress();
});
</script>

<style scoped>
.survey-list, .progress-list {
  list-style: none;
  padding: 0;
}
.subtext {
  font-size: 0.7rem!important;
}
.survey-list li, .progress-list li {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
}
.survey-list li a, .progress-list li a {
  text-decoration: none;
  color: var(--p-button-primary-background);
  font-weight: 600;
}
.survey-list li span, .progress-list li span {
  font-size: 14px;
  margin-left: 10px;
  color: #6c757d;
}
.delete-action {
  color: #dc3545;
}
.edit-survey label {
    display:block;
}
.p-field {
    margin-bottom: 1rem;
}
</style>
